var windowW,
    windowH,
    bannerH,
    headerH,
    headerTop,
    menuContH,
    boxScrollOffsetLeft,
    boxScrollOffsetTop,
    footerTop,
    footerH,
    stopScroll

/*--------------------------------------------------
FUNZIONI
--------------------------------------------------*/
function myVars() {
    windowW = $(window).width();
    windowH = $(window).height();
    bannerH = $('.banner-top').outerHeight();
    headerH = $('header').outerHeight();
    headerTop = $('header').offset().top;
    menuContH = $('.menu-container').outerHeight();
    footerTop = $('footer').offset().top;
    footerH = $('footer').outerHeight();
    stopScroll = footerTop - (windowH / 1.5);
    if ($('.box-scroll').length) {
        boxScrollOffsetTop = $('.box-scroll').offset().top;
    }
}

function controllScroll(scrollo) {
    //console.log(scrollo);
    if ((scrollo + windowH - footerH) > headerTop) {
        $('#scroll_top').addClass('footer-scroll');
    } else {
        $('#scroll_top').removeClass('footer-scroll');
    }

    if (windowW > 767) {
        if (scrollo > headerH + headerTop + menuContH) {
            $('header').not($('#checkout .checkout-step .address-item header')).addClass('fixed');
            $('.menu-container').addClass('fixed').css({ 'top': headerH });
            $('body').css({ 'padding-top': headerH + menuContH });
            $('.ui-autocomplete').addClass('search-scroll');
        } else {
            $('header, .menu-container').removeClass('fixed');
            $('body').css({ 'padding-top': 0 });
            $('.ui-autocomplete').removeClass('search-scroll');
        }
        if (scrollo > boxScrollOffsetTop && scrollo < stopScroll) {
            $('.box-scroll').css({ 'top': scrollo - headerTop });
        } else {
            $('.box-scroll').css({ 'top': 0 });
        }
    } else {
        if (scrollo > headerTop) {
            $('header').addClass('fixed');
            $('body').css({ 'padding-top': headerH });
        } else {
            $('header').removeClass('fixed');
            $('body').css({ 'padding-top': 0 });
        }
    }
}

function maniMenu() {
    $('.hamburger').on('click', function () {
        $('#menu').toggleClass('active');
        return false;
    });
    $('#menu .chiudi').on('click', function () {
        $('#menu').removeClass('active');
        setTimeout(function () {
            $('#menu .grower').removeClass('close');
            $('#menu ul.active').removeClass('active');
            $('#menu a.selected').removeClass('selected');
        }, 1000);
        return false;
    });

    $('#menu .grower').on('click', function () {
        $(this).toggleClass('close');
        $(this).next().toggleClass('selected');
        $(this).next().next().toggleClass('active');
        return false;
    });
}

function maniMenuMobile() {
    $('.hamburger-mobile, .menu-container .chiudi').on('click', function () {
        $('.menu-container').toggleClass('active');
        return false;
    });
}

function searcMobile() {
    $('.lente a, .close-search').on('click', function () {
        $('.header form').toggleClass('fixed');
        return false;
    });
}

function subCart() {
    //console.log('subCart');
    $('header .cart_index_title').hover(function () {
        $('#subcart').addClass('pointer');
    }, function () {
        setTimeout(function () {
            if (!$('#subcart').hasClass('no-remove')) {
                $('#subcart').removeClass('pointer');
            }
        }, 1500);
    });

    $('#subcart').hover(function () {
        $(this).addClass('pointer no-remove');
    }, function () {
        $(this).removeClass('pointer no-remove');
    });
}

function sliderHp() {
    $('.slider-hp').not($('.slider-novita-section .slider-hp')).slick({
        dots: true,
        centerMode: true,
        centerPadding: '500px',
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    centerPadding: '215px'
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    centerPadding: '150px'
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 991,
                settings: {
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '20px'
                }
            }
        ]
    });
}

function sliderNovita() {
    $('.slider-novita-section .slider-hp').slick({
        dots: true,
        centerMode: true,
        centerPadding: '500px',
        slidesToShow: 1,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    centerPadding: '370px'
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    centerPadding: '150px'
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    centerPadding: '100px'
                }
            },
            {
                breakpoint: 991,
                settings: {
                    centerPadding: '40px'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerPadding: '20px'
                }
            }
        ]
    });
}

function sliderProdotti() {
    $('.slider-prodotti').not($('.slider-popolari-section .slider-prodotti')).slick({
        centerMode: true,
        centerPadding: '64px',
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '35px'
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '30px'
                }
            }
        ]
    });
}

function sliderPopolari() {
    $('.slider-popolari-section .slider-prodotti').not('.slider-altri-prodotti .slider-prodotti').slick({
        centerMode: true,
        centerPadding: '64px',
        slidesToShow: 8,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '35px'
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '30px'
                }
            }
        ]
    });
}

function sliderAltriProdotti() {
    $('.slider-altri-prodotti .slider-prodotti').slick({
        //centerMode: true,
        //centerPadding: '64px',
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '35px'
                }
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '30px'
                }
            }
        ]
    });
}

function sliderProdottiArrow() {
    var figureH = $('.slider-prodotti .item-prodotto figure').outerHeight();
    var arrowH = $('.slider-prodotti .slick-arrow').outerHeight();
    $('.slider-prodotti .slick-arrow').css({ 'top': (figureH / 2) - (arrowH / 2) });
}

function sliderPopolariArrow() {
    var figureH = $('.slider-popolari-section .item-prodotto figure').outerHeight();
    var arrowH = $('.slider-popolari-section .slick-arrow').outerHeight();
    $('.slider-popolari-section .slick-arrow').css({ 'top': (figureH / 2) - (arrowH / 2) });
}

function sliderOfferta() {
    $('.slider-offerta').slick({
        //arrows: false,
        dots: true
    });
}

function boxAnteprima() {
    $('.link-anteprima, .box-anteprima .chiudi').on('click', function () {
        $('.box-anteprima').toggleClass('active');
        var myPos = $(document).scrollTop();
        //console.log(myPos);
        $('body,html').stop().animate({ scrollTop: myPos }, 'slow');
        //return false;
    });

    $('.quick-view').on('click', function () {
        setTimeout(function () {
            sliderOfferta();
            changeImgUrl();
        }, 1000); /* PER LA PRODUZIONE SETTARE MENO SECONDI */
    });
}

function myTab() {
    $('.tabs li').on('click', function () {
        if (!$(this).hasClass('active')) {
            var myIndex = $(this).index();
            console.log(myIndex);
            $('.tabs li.active').removeClass('active');
            $(this).addClass('active');
            $('.cont-tabs .tab.active').removeClass('active');
            $('.cont-tabs .tab:eq(' + myIndex + ')').addClass('active');
        }
        return false;
    });
}

function changeView() {
    $('.change-view .list-view').on('click', function () {
        if (!$('.list-prodotti').hasClass('list-view')) {
            $('.list-prodotti').removeClass('grid-view');
            $('.list-prodotti').addClass('list-view');
            $('.grid-view').removeClass('active');
            $(this).addClass('active');
        }
        return false;
        wishlistPosition();
    });
    $('.change-view .grid-view').on('click', function () {
        if (!$('.list-prodotti').hasClass('grid-view')) {
            $('.list-prodotti').removeClass('list-view');
            $('.list-prodotti').addClass('grid-view');
            $('.list-view').removeClass('active');
            $(this).addClass('active');
        }
        return false;
        wishlistPosition();
    });
}

function myFaq() {
    $('.tit-faq').each(function () {
        $(this).on('click', function () {
            $(this).closest('.faq').toggleClass('active');
            return false;
        });
    });
}

function addWishlist() {
    $('.wishlist-button-add, .wishlist-add-to-new, .wishlist-list-item button').not($('.wishlist-list-item button.wishlist-list-item-actions')).on('click', function () {
        setTimeout(function () {
            if ($('.modal.fade').hasClass('show')) {
                $('footer').addClass('footer-modal');
            } else {
                $('footer').removeClass('footer-modal');
            }
        }, 200);
    });

    $('.modal-header .close, .modal-body .wishlist-list-item, .modal-footer .modal-cancel').on('click', function () {
        $('footer').removeClass('footer-modal');
    });
}

function wishlistPosition() {
    //SPOSTO L'ICONA WISHLIST PER ALLINEARLA ALLA CTA AGGIUNGI AL CARRELLO
    $('.item-prodotto .wishlist-button-add').not($('.list-prodotti .item-prodotto .wishlist-button-add')).each(function () {
        var myLeft = $(this).prev().width();
        var myHeight = $(this).prev().height();
        console.log(myLeft);
        $(this).css({ 'left': myLeft + $(this).width() + 16, 'height': myHeight });
    });
}

function sliderBannerTop() {

    if (windowW >= 768) {
        if ($('.banner-top').hasClass('slick-initialized')) {
            $('.banner-top').slick('unslick');
        }
    } else {
        $('.banner-top').slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
        });
    }
}

function productClick() {
    $('.item-prodotto a').on('click', function () {
        var productId = $(this).closest('.item-prodotto').attr('data-id-product');
        var productIdAttribute = $(this).closest('.item-prodotto').attr('data-id-product-attribute');

        cdcGtm.productClick(productId, productIdAttribute);

        console.log(dataLayer);
    });
}

function productAddinCart() {

    $('body').on('click', '.bootstrap-touchspin-up', function () {
        var productId = $(this).closest('.bootstrap-touchspin').find('input').attr('data-product-id');

        cdcGtm.addToCart(productId, '', 1, '', '');
    })
}

function productRemovefromCart() {

    $('body').on('click', '.bootstrap-touchspin-down', function(){
        console.log('rimosso');
        var productId = $(this).closest('.bootstrap-touchspin').find('input').attr('data-product-id');

        cdcGtm.removeFromCart(productId, '', 1);
    });
}

function reloadAddRemoveVoucher() {
    jQuery('.promo-name a').on('click', function() {
        window.location.reload();
       });
    
    jQuery('.promo-code button').on('click', function() {
        window.location.reload();
    });
}

function hidePurchaseBtn() {
    $('#checkout').on('change', '#checkout-payment-step input[type=radio][name=payment-option]', function (e) {

        let paymentName = $(this).attr('data-module-name');

        if (paymentName == 'paypal' || paymentName == 'paypal_bnpl') {
            $('.ps-shown-by-js').hide();
        } else {
            $('.ps-shown-by-js').show();
        }
    });
}

//SOLO PER MONTAGGIO CARRELLO POI ELIMINARE
function changeImgUrl() {
    $("img").each((index, img) => {
        img.src = img.src.replace("http://localhost/viviincampagna/", "https://phpstack-1097830-3880547.cloudwaysapps.com/");
        img.src = img.src.replace("http://localhost/themes/", "https://phpstack-1097830-3880547.cloudwaysapps.com/themes/");
    });
}
//FINE SOLO PER MONTAGGIO CARRELLO POI ELIMINARE

/*--------------------------------------------------
DOC READY
--------------------------------------------------*/
$(document).ready(function (e) {

    if ($(window).width() > 1199) {
        document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale=' + (1 / window.devicePixelRatio));
        console.log(window.devicePixelRatio);
    }
    myVars();
    maniMenu();
    maniMenuMobile();
    searcMobile();
    subCart();
    sliderHp();
    sliderNovita();
    sliderProdotti();
    sliderProdottiArrow();
    sliderPopolari();
    sliderPopolariArrow();
    sliderAltriProdotti();
    sliderOfferta();
    boxAnteprima();
    myTab();
    changeView();
    myFaq();
    setTimeout(function () {
        addWishlist();
    }, 500);
    wishlistPosition();
    sliderBannerTop();
    productClick();
    productAddinCart();
    productRemovefromCart();
    hidePurchaseBtn();
    reloadAddRemoveVoucher()
    //SOLO PER MONTAGGIO CARRELLO POI ELIMINARE
    //changeImgUrl();    

    $('.remove-from-cart, .add-to-cart').on('click', function () {
        setTimeout(function () {
            subCart();
        }, 2000);

    });

});

/*--------------------------------------------------
WIN RESIZE
--------------------------------------------------*/
$(window).resize(function () {
    myVars();
    sliderProdottiArrow();
    sliderPopolariArrow();
    sliderBannerTop();
});

/*--------------------------------------------------
WIN LOAD
--------------------------------------------------*/
$(window).on('load', function () {
    myVars();
    sliderProdottiArrow();
    sliderPopolariArrow();

    $('.ui-autocomplete-input').on('focus', function () {
        //console.log('ricerca');
        $('.ui-autocomplete').css({ 'max-width': $('.ui-autocomplete-input').outerWidth(), 'top': 130 });
    });

});

/*--------------------------------------------------
WIN SCROLL
--------------------------------------------------*/
$(window).scroll(function (e) {
    controllScroll($(document).scrollTop());
});